import * as qs from 'qs'

class MyRoutePaths {
  localePath: LocalePathFunction
  admin: AdminRoutePaths

  constructor(localePath: any) {
    this.localePath = localePath
    this.admin = new AdminRoutePaths()
  }

  home() {
    return this.localePath(`/`)
  }

  login() {
    return '/auth/login'
  }

  logout() {
    return `/auth/logout`
  }

  about() {
    return this.localePath(`/about`)
  }

  cookiePolicy() {
    return this.localePath(`/support/cookie_policy`)
  }

  posts(params? : ListParams) {
    const page = params?.page
    if (page && page !== 1) {
      return this.localePath({
        path: `/p`,
        query: {
          page: page.toString()
        }
      })
    } else {
      return this.localePath('/p')
    }
  }

  post(id: string) {
    return this.localePath(`/p/${id}`)
  }

  postSeriesList(params? : ListParams) {
    const page = params?.page
    if (page && page !== 1) {
      return this.localePath({
        path: `/series`,
        query: {
          page: page.toString()
        }
      })
    } else {
      return this.localePath('/series')
    }
  }

  postSeries(id: string) {
    return this.localePath(`/series/${id}`)
  }

  journals(params? : ListParams) {
    const page = params?.page
    if (page && page !== 1) {
      return this.localePath({
        path: `/journals`,
        query: {
          page: page.toString()
        }
      })
    } else {
      return this.localePath('/journals')
    }
  }

  journal(id: string) {
    return this.localePath(`/journals/${id}`)
  }

  areaMaps() {
    return this.localePath(`/areamaps`)
  }

  areaMap(id: string) {
    return this.localePath(`/areamaps/${id}`)
  }

  places({ areaMapId }: { areaMapId: string }) {
    return this.localePath(`/areamaps/${areaMapId}/places`)
  }

  place(id: string, { areaMapId }: { areaMapId: string }) {
    return this.localePath(`/areamaps/${areaMapId}/places/${id}`)
  }
}

class AdminRoutePaths {
  home() {
    return `/admin/`
  }

  areaMaps() {
    return `/admin/areaMaps`
  }

  journals() {
    return `/admin/journals`
  }

  posts() {
    return `/admin/posts`
  }

  post(id: string) {
    return `/admin/posts/${id}`
  }

  postSeriesList(params? : ListParams) {
    const page = params?.page
    if (!page && page !== 1) {
      return `/admin/postSeries`
    } else {
      return `/admin/postSeries?` + qs.stringify({
        page: page,
      })
    }
  }

  postSeriesNew(): string {
    return `/admin/postSeries/new`
  }

  postSeries(id: string) {
    return `/admin/postSeries/${id}`
  }

  photoSets(): string {
    return `/admin/photosSets`
  }

  bucketImages(): string {
    return `/admin/bucketImages`
  }

  bucketImage(id: string): string {
    return `/admin/bucketImages/${id}`
  }

  selectableTags(): string {
    return `/admin/selectableTags`
  }

  youtubeVideos() {
    return '/admin/youtube/videos'
  }

  accessLogs() {
    return '/admin/accessLogs'
  }

  twoFactor() {
    return '/admin/security/two-factor'
  }
}

export const useMyRoutePaths = () => {
  const localePath = useLocalePath()
  return new MyRoutePaths(localePath)
}

interface ListParams {
  page?: number
}