<script setup lang="ts">
import type {ApiPostSimpleProps} from "~/projectCommon/frontend/model/ApiPostProps";
import {useMyRoutePaths} from "~/mylib/MyRoutePaths";
import {dateFormat} from "~/stCommon/datetime/DateFormatter";

const {t} = useI18n()

const props = defineProps<{
  posts: ApiPostSimpleProps[]
}>()

const routePaths = useMyRoutePaths()
</script>

<template>
  <div class="container">
    <ul class="row">
      <li v-for="post in props.posts" :key="post.id" class="col-12 col-md-6 post-list-item">
        <div class="post-list-item-div">
          <div class="image-div">
            <a v-if="post.ogpImageFile" :href="routePaths.post(post.id)">
              <img :src="post.ogpImageFile?.displayImageFile.url" :alt="post.ogpImageFile?.displayImageFile.url" >
            </a>
          </div>
          <div class="text-div">
            <h3 class="post-title"><a :href="routePaths.post(post.id)">{{ post.title }}</a></h3>
            <p class="post-excerpt">{{ post.excerpt }}</p>
            <p class="post-metadata">
              <i class="fa-light fa-clock me-1"></i>
              <time>{{ dateFormat(post.postedAtISO8601).localizedYMD() }}</time>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use 'node_modules/bootstrap/scss/bootstrap' as bs;

ul.row {
  padding: 0;
  list-style: none;
}

@include bs.media-breakpoint-down(md) {
  .post-list-item:nth-of-type(odd) {
    background-color: #f0f0f0;
  }
}

.post-list-item-div {
  display: flex;
  margin-bottom: 8px;
  padding: 8px;
  width: 100%;

  .image-div {
    width: 30%;
    a img {
      display: block;
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
  }

  .text-div {
    width: 70%;
    padding-left: 15px;
    a {
      display: block;
      text-decoration: none;
      height: 100%;
    }

    .post-title {
      display: flex;
      margin: 0 0 4px 0;
      padding: 0;
      font-size: 1.2rem;
      a {
        text-decoration: underline;
      }
    }
    .post-excerpt {
      color: black;
      margin-bottom: 0.25rem;
    }
    .post-metadata {
      font-size: 0.9rem;
      color: #1a1e21;
    }
  }
}
</style>