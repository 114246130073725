import { default as warmupJKWrd8XA06Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/_ah/warmup.vue?macro=true";
import { default as indexT22jZqR3wgMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/about/index.vue?macro=true";
import { default as indexY2kAuqXGElMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/accessLogs/index.vue?macro=true";
import { default as _91id_93OlIxVbQJWiMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[id].vue?macro=true";
import { default as _91id_938dvXyOHzpkMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/[id].vue?macro=true";
import { default as indexVzr2HXO1fYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/index.vue?macro=true";
import { default as newFKiQJ6hBpKMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/new.vue?macro=true";
import { default as indexbziLCk2A6gMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/index.vue?macro=true";
import { default as newPGswjfzjsfMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/new.vue?macro=true";
import { default as _91id_93jr6bklXfB3Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[id].vue?macro=true";
import { default as indexSZvNjoDLRaMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/index.vue?macro=true";
import { default as newELHYW2J8AOMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/new.vue?macro=true";
import { default as _91id_93LSEGdANkAjMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/[id].vue?macro=true";
import { default as indexbIdYlDaeJgMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/index.vue?macro=true";
import { default as auth9pITrzqB7oMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/facebook/auth.vue?macro=true";
import { default as indexM9dmp5gYYOMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/index.vue?macro=true";
import { default as _91id_93Fn9noRgxLfMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/[id].vue?macro=true";
import { default as indexjytIlQ4HrBMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/index.vue?macro=true";
import { default as new5G7dxeGa7pMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/new.vue?macro=true";
import { default as _91id_93DcTPPBoKeAMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/[id].vue?macro=true";
import { default as indexZnXnuMyjKPMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/index.vue?macro=true";
import { default as newqbUdkrP1cWMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/new.vue?macro=true";
import { default as _91id_93_45previewXu7H5U5epYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id]-preview.vue?macro=true";
import { default as _91id_93bAzcT6s6YVMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id].vue?macro=true";
import { default as indexZTIh5vStizMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/index.vue?macro=true";
import { default as newhrILTdFowsMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/new.vue?macro=true";
import { default as _91id_93Efh7I3SBPgMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/[id].vue?macro=true";
import { default as index4jOOsc3Ex8Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/index.vue?macro=true";
import { default as newSf0zXriKOCMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/new.vue?macro=true";
import { default as two_45factorhATXOWi90BMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/security/two-factor.vue?macro=true";
import { default as _91id_937r6K0Vy9RGMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/[id].vue?macro=true";
import { default as indext643pZB54xMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/index.vue?macro=true";
import { default as newCCyzvvA13GMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/new.vue?macro=true";
import { default as index9IgUKxopDnMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/index.vue?macro=true";
import { default as translationPR6wFCHo3qMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/subtitles/translation.vue?macro=true";
import { default as _91id_93bwTMnEdALbMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/[id].vue?macro=true";
import { default as indexYAOfrRMyx0Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/index.vue?macro=true";
import { default as new9drQC6SMiaMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/new.vue?macro=true";
import { default as indexrFJfj8spE1Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/index.vue?macro=true";
import { default as _91id_93pf68gPxkZOMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[id].vue?macro=true";
import { default as _91id_93zmRdOJcGTMMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[placeId]/p/[id].vue?macro=true";
import { default as indexzgRlgVU9cBMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/index.vue?macro=true";
import { default as indexLFTgz8NhADMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/index.vue?macro=true";
import { default as loginj6V2db1jJkMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/auth/login.vue?macro=true";
import { default as logoutleQv66osVlMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/auth/logout.vue?macro=true";
import { default as indexnYpUom5MSuMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/index.vue?macro=true";
import { default as _91id_93ONK8Wl5CTMMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/[id].vue?macro=true";
import { default as indexOcxbAqf0fHMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/index.vue?macro=true";
import { default as _91id_93SJQHezlYbaMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/[id].vue?macro=true";
import { default as indexsS8BxZX3w8Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/index.vue?macro=true";
import { default as _91id_93bCnn5qOESQMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/redirect/places/[id].vue?macro=true";
import { default as _91id_93FLHxdamUNQMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/[id].vue?macro=true";
import { default as indexBw9tBTNh8dMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/index.vue?macro=true";
import { default as cookie_policyz56jorFfvYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/support/cookie_policy.vue?macro=true";
import { default as indexgoDMX1OEDYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/index.vue?macro=true";
import { default as accessBEbAenNp8jMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/access.vue?macro=true";
import { default as akagijoF8YjNTWpycMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/akagijo.vue?macro=true";
import { default as hananoiwayaKICwpSBzqxMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/hananoiwaya.vue?macro=true";
import { default as indexvyV43yHY5yMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/index.vue?macro=true";
import { default as mapSgpmRXGnx2Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/map.vue?macro=true";
import { default as matsumototougeFBWrIG1uafMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/matsumototouge.vue?macro=true";
import { default as onigajoLbUHqJw19MMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/onigajo.vue?macro=true";
import { default as sengyogKDBFNAG1YMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/sengyo.vue?macro=true";
import { default as senmaidaV0hrXZjhHKMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/senmaida.vue?macro=true";
import { default as shichirimihamaxPW9LH7TabMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shichirimihama.vue?macro=true";
import { default as shishiiwa4VWdONCFd1Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shishiiwa.vue?macro=true";
import { default as yunoguchionsen9BOQC8R72eMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/yunoguchionsen.vue?macro=true";
import { default as hayatamataishaWQoPFJ8aaoMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/hayatamataisha.vue?macro=true";
import { default as indexrr9Ws4EaLpMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/index.vue?macro=true";
import { default as kamikura2uU6OjGoXBMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kamikura.vue?macro=true";
import { default as kumanoohashiSbxCUBOEpNMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kumanoohashi.vue?macro=true";
import { default as kushimoto1hthIkH0prMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kushimoto.vue?macro=true";
import { default as mapQLAu3SCyreMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/map.vue?macro=true";
import { default as indexh1Ht8cwzoKMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/nagano/matsumoto/index.vue?macro=true";
import { default as indexgt2GR5EM6jMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/index.vue?macro=true";
import { default as map9wFUZecgQiMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/map.vue?macro=true";
export default [
  {
    name: "_ah-warmup___ja",
    path: "/_ah/warmup",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/_ah/warmup.vue")
  },
  {
    name: "_ah-warmup___en",
    path: "/en/_ah/warmup",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/_ah/warmup.vue")
  },
  {
    name: "about___ja",
    path: "/about",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/about/index.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/about/index.vue")
  },
  {
    name: "admin-accessLogs___ja",
    path: "/admin/accessLogs",
    meta: indexY2kAuqXGElMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/accessLogs/index.vue")
  },
  {
    name: "admin-accessLogs___en",
    path: "/en/admin/accessLogs",
    meta: indexY2kAuqXGElMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/accessLogs/index.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-id___ja",
    path: "/admin/areaMaps/:areaMapId()/places/:id()",
    meta: _91id_93OlIxVbQJWiMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[id].vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-id___en",
    path: "/en/admin/areaMaps/:areaMapId()/places/:id()",
    meta: _91id_93OlIxVbQJWiMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[id].vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-placeId-posts-id___ja",
    path: "/admin/areaMaps/:areaMapId()/places/:placeId()/posts/:id()",
    meta: _91id_938dvXyOHzpkMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/[id].vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-placeId-posts-id___en",
    path: "/en/admin/areaMaps/:areaMapId()/places/:placeId()/posts/:id()",
    meta: _91id_938dvXyOHzpkMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/[id].vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-placeId-posts___ja",
    path: "/admin/areaMaps/:areaMapId()/places/:placeId()/posts",
    meta: indexVzr2HXO1fYMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/index.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-placeId-posts___en",
    path: "/en/admin/areaMaps/:areaMapId()/places/:placeId()/posts",
    meta: indexVzr2HXO1fYMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/index.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-placeId-posts-new___ja",
    path: "/admin/areaMaps/:areaMapId()/places/:placeId()/posts/new",
    meta: newFKiQJ6hBpKMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/new.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-placeId-posts-new___en",
    path: "/en/admin/areaMaps/:areaMapId()/places/:placeId()/posts/new",
    meta: newFKiQJ6hBpKMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/new.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places___ja",
    path: "/admin/areaMaps/:areaMapId()/places",
    meta: indexbziLCk2A6gMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/index.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places___en",
    path: "/en/admin/areaMaps/:areaMapId()/places",
    meta: indexbziLCk2A6gMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/index.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-new___ja",
    path: "/admin/areaMaps/:areaMapId()/places/new",
    meta: newPGswjfzjsfMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/new.vue")
  },
  {
    name: "admin-areaMaps-areaMapId-places-new___en",
    path: "/en/admin/areaMaps/:areaMapId()/places/new",
    meta: newPGswjfzjsfMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/new.vue")
  },
  {
    name: "admin-areaMaps-id___ja",
    path: "/admin/areaMaps/:id()",
    meta: _91id_93jr6bklXfB3Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[id].vue")
  },
  {
    name: "admin-areaMaps-id___en",
    path: "/en/admin/areaMaps/:id()",
    meta: _91id_93jr6bklXfB3Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[id].vue")
  },
  {
    name: "admin-areaMaps___ja",
    path: "/admin/areaMaps",
    meta: indexSZvNjoDLRaMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/index.vue")
  },
  {
    name: "admin-areaMaps___en",
    path: "/en/admin/areaMaps",
    meta: indexSZvNjoDLRaMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/index.vue")
  },
  {
    name: "admin-areaMaps-new___ja",
    path: "/admin/areaMaps/new",
    meta: newELHYW2J8AOMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/new.vue")
  },
  {
    name: "admin-areaMaps-new___en",
    path: "/en/admin/areaMaps/new",
    meta: newELHYW2J8AOMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/new.vue")
  },
  {
    name: "admin-bucketImages-id___ja",
    path: "/admin/bucketImages/:id()",
    meta: _91id_93LSEGdANkAjMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/[id].vue")
  },
  {
    name: "admin-bucketImages-id___en",
    path: "/en/admin/bucketImages/:id()",
    meta: _91id_93LSEGdANkAjMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/[id].vue")
  },
  {
    name: "admin-bucketImages___ja",
    path: "/admin/bucketImages",
    meta: indexbIdYlDaeJgMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/index.vue")
  },
  {
    name: "admin-bucketImages___en",
    path: "/en/admin/bucketImages",
    meta: indexbIdYlDaeJgMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/index.vue")
  },
  {
    name: "admin-facebook-auth___ja",
    path: "/admin/facebook/auth",
    meta: auth9pITrzqB7oMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/facebook/auth.vue")
  },
  {
    name: "admin-facebook-auth___en",
    path: "/en/admin/facebook/auth",
    meta: auth9pITrzqB7oMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/facebook/auth.vue")
  },
  {
    name: "admin___ja",
    path: "/admin",
    meta: indexM9dmp5gYYOMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexM9dmp5gYYOMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/index.vue")
  },
  {
    name: "admin-journals-id___ja",
    path: "/admin/journals/:id()",
    meta: _91id_93Fn9noRgxLfMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/[id].vue")
  },
  {
    name: "admin-journals-id___en",
    path: "/en/admin/journals/:id()",
    meta: _91id_93Fn9noRgxLfMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/[id].vue")
  },
  {
    name: "admin-journals___ja",
    path: "/admin/journals",
    meta: indexjytIlQ4HrBMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/index.vue")
  },
  {
    name: "admin-journals___en",
    path: "/en/admin/journals",
    meta: indexjytIlQ4HrBMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/index.vue")
  },
  {
    name: "admin-journals-new___ja",
    path: "/admin/journals/new",
    meta: new5G7dxeGa7pMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/new.vue")
  },
  {
    name: "admin-journals-new___en",
    path: "/en/admin/journals/new",
    meta: new5G7dxeGa7pMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/new.vue")
  },
  {
    name: "admin-photoSets-id___ja",
    path: "/admin/photoSets/:id()",
    meta: _91id_93DcTPPBoKeAMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/[id].vue")
  },
  {
    name: "admin-photoSets-id___en",
    path: "/en/admin/photoSets/:id()",
    meta: _91id_93DcTPPBoKeAMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/[id].vue")
  },
  {
    name: "admin-photoSets___ja",
    path: "/admin/photoSets",
    meta: indexZnXnuMyjKPMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/index.vue")
  },
  {
    name: "admin-photoSets___en",
    path: "/en/admin/photoSets",
    meta: indexZnXnuMyjKPMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/index.vue")
  },
  {
    name: "admin-photoSets-new___ja",
    path: "/admin/photoSets/new",
    meta: newqbUdkrP1cWMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/new.vue")
  },
  {
    name: "admin-photoSets-new___en",
    path: "/en/admin/photoSets/new",
    meta: newqbUdkrP1cWMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/new.vue")
  },
  {
    name: "admin-posts-id-preview___ja",
    path: "/admin/posts/:id()-preview",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id]-preview.vue")
  },
  {
    name: "admin-posts-id-preview___en",
    path: "/en/admin/posts/:id()-preview",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id]-preview.vue")
  },
  {
    name: "admin-posts-id___ja",
    path: "/admin/posts/:id()",
    meta: _91id_93bAzcT6s6YVMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id].vue")
  },
  {
    name: "admin-posts-id___en",
    path: "/en/admin/posts/:id()",
    meta: _91id_93bAzcT6s6YVMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id].vue")
  },
  {
    name: "admin-posts___ja",
    path: "/admin/posts",
    meta: indexZTIh5vStizMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/index.vue")
  },
  {
    name: "admin-posts___en",
    path: "/en/admin/posts",
    meta: indexZTIh5vStizMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/index.vue")
  },
  {
    name: "admin-posts-new___ja",
    path: "/admin/posts/new",
    meta: newhrILTdFowsMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/new.vue")
  },
  {
    name: "admin-posts-new___en",
    path: "/en/admin/posts/new",
    meta: newhrILTdFowsMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/new.vue")
  },
  {
    name: "admin-postSeries-id___ja",
    path: "/admin/postSeries/:id()",
    meta: _91id_93Efh7I3SBPgMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/[id].vue")
  },
  {
    name: "admin-postSeries-id___en",
    path: "/en/admin/postSeries/:id()",
    meta: _91id_93Efh7I3SBPgMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/[id].vue")
  },
  {
    name: "admin-postSeries___ja",
    path: "/admin/postSeries",
    meta: index4jOOsc3Ex8Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/index.vue")
  },
  {
    name: "admin-postSeries___en",
    path: "/en/admin/postSeries",
    meta: index4jOOsc3Ex8Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/index.vue")
  },
  {
    name: "admin-postSeries-new___ja",
    path: "/admin/postSeries/new",
    meta: newSf0zXriKOCMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/new.vue")
  },
  {
    name: "admin-postSeries-new___en",
    path: "/en/admin/postSeries/new",
    meta: newSf0zXriKOCMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/new.vue")
  },
  {
    name: "admin-security-two-factor___ja",
    path: "/admin/security/two-factor",
    meta: two_45factorhATXOWi90BMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/security/two-factor.vue")
  },
  {
    name: "admin-security-two-factor___en",
    path: "/en/admin/security/two-factor",
    meta: two_45factorhATXOWi90BMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/security/two-factor.vue")
  },
  {
    name: "admin-selectableTags-id___ja",
    path: "/admin/selectableTags/:id()",
    meta: _91id_937r6K0Vy9RGMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/[id].vue")
  },
  {
    name: "admin-selectableTags-id___en",
    path: "/en/admin/selectableTags/:id()",
    meta: _91id_937r6K0Vy9RGMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/[id].vue")
  },
  {
    name: "admin-selectableTags___ja",
    path: "/admin/selectableTags",
    meta: indext643pZB54xMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/index.vue")
  },
  {
    name: "admin-selectableTags___en",
    path: "/en/admin/selectableTags",
    meta: indext643pZB54xMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/index.vue")
  },
  {
    name: "admin-selectableTags-new___ja",
    path: "/admin/selectableTags/new",
    meta: newCCyzvvA13GMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/new.vue")
  },
  {
    name: "admin-selectableTags-new___en",
    path: "/en/admin/selectableTags/new",
    meta: newCCyzvvA13GMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/new.vue")
  },
  {
    name: "admin-youtube___ja",
    path: "/admin/youtube",
    meta: index9IgUKxopDnMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/index.vue")
  },
  {
    name: "admin-youtube___en",
    path: "/en/admin/youtube",
    meta: index9IgUKxopDnMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/index.vue")
  },
  {
    name: "admin-youtube-subtitles-translation___ja",
    path: "/admin/youtube/subtitles/translation",
    meta: translationPR6wFCHo3qMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/subtitles/translation.vue")
  },
  {
    name: "admin-youtube-subtitles-translation___en",
    path: "/en/admin/youtube/subtitles/translation",
    meta: translationPR6wFCHo3qMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/subtitles/translation.vue")
  },
  {
    name: "admin-youtube-videos-id___ja",
    path: "/admin/youtube/videos/:id()",
    meta: _91id_93bwTMnEdALbMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/[id].vue")
  },
  {
    name: "admin-youtube-videos-id___en",
    path: "/en/admin/youtube/videos/:id()",
    meta: _91id_93bwTMnEdALbMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/[id].vue")
  },
  {
    name: "admin-youtube-videos___ja",
    path: "/admin/youtube/videos",
    meta: indexYAOfrRMyx0Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/index.vue")
  },
  {
    name: "admin-youtube-videos___en",
    path: "/en/admin/youtube/videos",
    meta: indexYAOfrRMyx0Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/index.vue")
  },
  {
    name: "admin-youtube-videos-new___ja",
    path: "/admin/youtube/videos/new",
    meta: new9drQC6SMiaMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/new.vue")
  },
  {
    name: "admin-youtube-videos-new___en",
    path: "/en/admin/youtube/videos/new",
    meta: new9drQC6SMiaMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/new.vue")
  },
  {
    name: "areamaps-areaMapId___ja",
    path: "/areamaps/:areaMapId()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/index.vue")
  },
  {
    name: "areamaps-areaMapId___en",
    path: "/en/areamaps/:areaMapId()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/index.vue")
  },
  {
    name: "areamaps-areaMapId-places-id___ja",
    path: "/areamaps/:areaMapId()/places/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[id].vue")
  },
  {
    name: "areamaps-areaMapId-places-id___en",
    path: "/en/areamaps/:areaMapId()/places/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[id].vue")
  },
  {
    name: "areamaps-areaMapId-places-placeId-p-id___ja",
    path: "/areamaps/:areaMapId()/places/:placeId()/p/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[placeId]/p/[id].vue")
  },
  {
    name: "areamaps-areaMapId-places-placeId-p-id___en",
    path: "/en/areamaps/:areaMapId()/places/:placeId()/p/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[placeId]/p/[id].vue")
  },
  {
    name: "areamaps-areaMapId-places___ja",
    path: "/areamaps/:areaMapId()/places",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/index.vue")
  },
  {
    name: "areamaps-areaMapId-places___en",
    path: "/en/areamaps/:areaMapId()/places",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/index.vue")
  },
  {
    name: "areamaps___ja",
    path: "/areamaps",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/index.vue")
  },
  {
    name: "areamaps___en",
    path: "/en/areamaps",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/index.vue")
  },
  {
    name: "auth-login___ja",
    path: "/auth/login",
    meta: loginj6V2db1jJkMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/auth/login.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    meta: loginj6V2db1jJkMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/auth/login.vue")
  },
  {
    name: "auth-logout___ja",
    path: "/auth/logout",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___en",
    path: "/en/auth/logout",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/auth/logout.vue")
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexnYpUom5MSuMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexnYpUom5MSuMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/index.vue")
  },
  {
    name: "journals-id___ja",
    path: "/journals/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/[id].vue")
  },
  {
    name: "journals-id___en",
    path: "/en/journals/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/[id].vue")
  },
  {
    name: "journals___ja",
    path: "/journals",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/index.vue")
  },
  {
    name: "journals___en",
    path: "/en/journals",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/index.vue")
  },
  {
    name: "p-id___ja",
    path: "/p/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/[id].vue")
  },
  {
    name: "p-id___en",
    path: "/en/p/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/[id].vue")
  },
  {
    name: "p___ja",
    path: "/p",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/index.vue")
  },
  {
    name: "p___en",
    path: "/en/p",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/index.vue")
  },
  {
    name: "redirect-places-id___ja",
    path: "/redirect/places/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/redirect/places/[id].vue")
  },
  {
    name: "redirect-places-id___en",
    path: "/en/redirect/places/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/redirect/places/[id].vue")
  },
  {
    name: "series-id___ja",
    path: "/series/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/[id].vue")
  },
  {
    name: "series-id___en",
    path: "/en/series/:id()",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/[id].vue")
  },
  {
    name: "series___ja",
    path: "/series",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/index.vue")
  },
  {
    name: "series___en",
    path: "/en/series",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/index.vue")
  },
  {
    name: "support-cookie_policy___ja",
    path: "/support/cookie_policy",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/support/cookie_policy.vue")
  },
  {
    name: "support-cookie_policy___en",
    path: "/en/support/cookie_policy",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/support/cookie_policy.vue")
  },
  {
    name: "travels___ja",
    path: "/travels",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/index.vue")
  },
  {
    name: "travels___en",
    path: "/en/travels",
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/index.vue")
  },
  {
    name: "travels-kumano-kumanoshi-access___ja",
    path: "/travels/kumano/kumanoshi/access",
    meta: accessBEbAenNp8jMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/access.vue")
  },
  {
    name: "travels-kumano-kumanoshi-access___en",
    path: "/en/travels/kumano/kumanoshi/access",
    meta: accessBEbAenNp8jMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/access.vue")
  },
  {
    name: "travels-kumano-kumanoshi-akagijo___ja",
    path: "/travels/kumano/kumanoshi/akagijo",
    meta: akagijoF8YjNTWpycMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/akagijo.vue")
  },
  {
    name: "travels-kumano-kumanoshi-akagijo___en",
    path: "/en/travels/kumano/kumanoshi/akagijo",
    meta: akagijoF8YjNTWpycMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/akagijo.vue")
  },
  {
    name: "travels-kumano-kumanoshi-hananoiwaya___ja",
    path: "/travels/kumano/kumanoshi/hananoiwaya",
    meta: hananoiwayaKICwpSBzqxMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/hananoiwaya.vue")
  },
  {
    name: "travels-kumano-kumanoshi-hananoiwaya___en",
    path: "/en/travels/kumano/kumanoshi/hananoiwaya",
    meta: hananoiwayaKICwpSBzqxMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/hananoiwaya.vue")
  },
  {
    name: "travels-kumano-kumanoshi___ja",
    path: "/travels/kumano/kumanoshi",
    meta: indexvyV43yHY5yMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/index.vue")
  },
  {
    name: "travels-kumano-kumanoshi___en",
    path: "/en/travels/kumano/kumanoshi",
    meta: indexvyV43yHY5yMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/index.vue")
  },
  {
    name: "travels-kumano-kumanoshi-map___ja",
    path: "/travels/kumano/kumanoshi/map",
    meta: mapSgpmRXGnx2Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/map.vue")
  },
  {
    name: "travels-kumano-kumanoshi-map___en",
    path: "/en/travels/kumano/kumanoshi/map",
    meta: mapSgpmRXGnx2Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/map.vue")
  },
  {
    name: "travels-kumano-kumanoshi-matsumototouge___ja",
    path: "/travels/kumano/kumanoshi/matsumototouge",
    meta: matsumototougeFBWrIG1uafMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/matsumototouge.vue")
  },
  {
    name: "travels-kumano-kumanoshi-matsumototouge___en",
    path: "/en/travels/kumano/kumanoshi/matsumototouge",
    meta: matsumototougeFBWrIG1uafMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/matsumototouge.vue")
  },
  {
    name: "travels-kumano-kumanoshi-onigajo___ja",
    path: "/travels/kumano/kumanoshi/onigajo",
    meta: onigajoLbUHqJw19MMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/onigajo.vue")
  },
  {
    name: "travels-kumano-kumanoshi-onigajo___en",
    path: "/en/travels/kumano/kumanoshi/onigajo",
    meta: onigajoLbUHqJw19MMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/onigajo.vue")
  },
  {
    name: "travels-kumano-kumanoshi-sengyo___ja",
    path: "/travels/kumano/kumanoshi/sengyo",
    meta: sengyogKDBFNAG1YMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/sengyo.vue")
  },
  {
    name: "travels-kumano-kumanoshi-sengyo___en",
    path: "/en/travels/kumano/kumanoshi/sengyo",
    meta: sengyogKDBFNAG1YMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/sengyo.vue")
  },
  {
    name: "travels-kumano-kumanoshi-senmaida___ja",
    path: "/travels/kumano/kumanoshi/senmaida",
    meta: senmaidaV0hrXZjhHKMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/senmaida.vue")
  },
  {
    name: "travels-kumano-kumanoshi-senmaida___en",
    path: "/en/travels/kumano/kumanoshi/senmaida",
    meta: senmaidaV0hrXZjhHKMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/senmaida.vue")
  },
  {
    name: "travels-kumano-kumanoshi-shichirimihama___ja",
    path: "/travels/kumano/kumanoshi/shichirimihama",
    meta: shichirimihamaxPW9LH7TabMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shichirimihama.vue")
  },
  {
    name: "travels-kumano-kumanoshi-shichirimihama___en",
    path: "/en/travels/kumano/kumanoshi/shichirimihama",
    meta: shichirimihamaxPW9LH7TabMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shichirimihama.vue")
  },
  {
    name: "travels-kumano-kumanoshi-shishiiwa___ja",
    path: "/travels/kumano/kumanoshi/shishiiwa",
    meta: shishiiwa4VWdONCFd1Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shishiiwa.vue")
  },
  {
    name: "travels-kumano-kumanoshi-shishiiwa___en",
    path: "/en/travels/kumano/kumanoshi/shishiiwa",
    meta: shishiiwa4VWdONCFd1Meta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shishiiwa.vue")
  },
  {
    name: "travels-kumano-kumanoshi-yunoguchionsen___ja",
    path: "/travels/kumano/kumanoshi/yunoguchionsen",
    meta: yunoguchionsen9BOQC8R72eMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/yunoguchionsen.vue")
  },
  {
    name: "travels-kumano-kumanoshi-yunoguchionsen___en",
    path: "/en/travels/kumano/kumanoshi/yunoguchionsen",
    meta: yunoguchionsen9BOQC8R72eMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/yunoguchionsen.vue")
  },
  {
    name: "travels-kumano-shingu-hayatamataisha___ja",
    path: "/travels/kumano/shingu/hayatamataisha",
    meta: hayatamataishaWQoPFJ8aaoMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/hayatamataisha.vue")
  },
  {
    name: "travels-kumano-shingu-hayatamataisha___en",
    path: "/en/travels/kumano/shingu/hayatamataisha",
    meta: hayatamataishaWQoPFJ8aaoMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/hayatamataisha.vue")
  },
  {
    name: "travels-kumano-shingu___ja",
    path: "/travels/kumano/shingu",
    meta: indexrr9Ws4EaLpMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/index.vue")
  },
  {
    name: "travels-kumano-shingu___en",
    path: "/en/travels/kumano/shingu",
    meta: indexrr9Ws4EaLpMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/index.vue")
  },
  {
    name: "travels-kumano-shingu-kamikura___ja",
    path: "/travels/kumano/shingu/kamikura",
    meta: kamikura2uU6OjGoXBMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kamikura.vue")
  },
  {
    name: "travels-kumano-shingu-kamikura___en",
    path: "/en/travels/kumano/shingu/kamikura",
    meta: kamikura2uU6OjGoXBMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kamikura.vue")
  },
  {
    name: "travels-kumano-shingu-kumanoohashi___ja",
    path: "/travels/kumano/shingu/kumanoohashi",
    meta: kumanoohashiSbxCUBOEpNMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kumanoohashi.vue")
  },
  {
    name: "travels-kumano-shingu-kumanoohashi___en",
    path: "/en/travels/kumano/shingu/kumanoohashi",
    meta: kumanoohashiSbxCUBOEpNMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kumanoohashi.vue")
  },
  {
    name: "travels-kumano-shingu-kushimoto___ja",
    path: "/travels/kumano/shingu/kushimoto",
    meta: kushimoto1hthIkH0prMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kushimoto.vue")
  },
  {
    name: "travels-kumano-shingu-kushimoto___en",
    path: "/en/travels/kumano/shingu/kushimoto",
    meta: kushimoto1hthIkH0prMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kushimoto.vue")
  },
  {
    name: "travels-kumano-shingu-map___ja",
    path: "/travels/kumano/shingu/map",
    meta: mapQLAu3SCyreMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/map.vue")
  },
  {
    name: "travels-kumano-shingu-map___en",
    path: "/en/travels/kumano/shingu/map",
    meta: mapQLAu3SCyreMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/map.vue")
  },
  {
    name: "travels-nagano-matsumoto___ja",
    path: "/travels/nagano/matsumoto",
    meta: indexh1Ht8cwzoKMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/nagano/matsumoto/index.vue")
  },
  {
    name: "travels-nagano-matsumoto___en",
    path: "/en/travels/nagano/matsumoto",
    meta: indexh1Ht8cwzoKMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/nagano/matsumoto/index.vue")
  },
  {
    name: "travels-shikoku-kochi___ja",
    path: "/travels/shikoku/kochi",
    meta: indexgt2GR5EM6jMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/index.vue")
  },
  {
    name: "travels-shikoku-kochi___en",
    path: "/en/travels/shikoku/kochi",
    meta: indexgt2GR5EM6jMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/index.vue")
  },
  {
    name: "travels-shikoku-kochi-map___ja",
    path: "/travels/shikoku/kochi/map",
    meta: map9wFUZecgQiMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/map.vue")
  },
  {
    name: "travels-shikoku-kochi-map___en",
    path: "/en/travels/shikoku/kochi/map",
    meta: map9wFUZecgQiMeta || {},
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/map.vue")
  }
]