<script setup lang="ts">
import {useMyAdminSession} from "~/mylib/common/cookie/myAdminSession";

const adminSession = useMyAdminSession()
const config = useRuntimeConfig()

let state = reactive({
  isSmallScreen: false,
})

const isSmallScreen = () => {
  const width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  return width <= 768
}

onMounted(() => {
  state.isSmallScreen = isSmallScreen()
  window.addEventListener('resize', () => {
    state.isSmallScreen = isSmallScreen()
  })
})
</script>

<!--
注意！templateのslotが2つ以上同時にrenderされないようにすること。
さもないとslotのコンポーネントでonMountedが2回以上呼ばれてしまう。
-->
<template>
  <div class="admin-root">
    <div v-if="adminSession.isLoggedIn()">
      <header class="container-fluid header">
        <h1 class="title">{{config.public.siteIdName}}管理画面</h1>
      </header>
      <div class="container">
        <div class="row">
          <div class="col-2">
            <AdminSidebar></AdminSidebar>
          </div>
          <div class="col-8">
            <div class="main-wrap min-vh-100">
              <main class="main">
                <slot />
              </main>
            </div>
          </div>
          <div class="col-2">
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- 未ログインならadmin/indexの内容を表示する -->
      <slot />
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;

  .title {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.main-wrap {
  border-left: 1px solid lightgray;
  width: 100%;
  .main {
    max-width: 800px;
    padding: 30px 15px 15px 50px;
  }
}

.main-wrap-xs {
  border-left: 1px solid lightgray;
  width: 100%;
  .main {
    width: 100%;
    padding: 30px 15px 15px 15px;
  }
}
</style>
