<script setup lang="ts">
import {useFrontendApiClient} from "~/composables/api/frontend/useFrontendApiClient";
import {useMyRoutePaths} from "~/mylib/MyRoutePaths";

const { locale, t } = useI18n()
const routePaths = useMyRoutePaths()
const config = useRuntimeConfig()

definePageMeta({
  layout: "empty",
})

const apiClient = useFrontendApiClient().top()
const { videos, journals, photos, posts, postSeriesList } = await apiClient.getData()
const state = reactive({
  videoIndex: 0,
  imageIndex: 0,
})

const youtubeChannelUrl = config.public.youtubeChannelUrl
const twitterUrl = `https://twitter.com/${config.public.twitterUsername}`
const instagramUrl = `https://instagram.com/${config.public.instagramUsername}`

let aboutWebsite: string
if (locale.value == 'en') {
  aboutWebsite = `
I live a relaxed life in the nature-rich Nanki-Kumano region, while taking trips to various places across Japan from time to time. I regularly post travel vlogs on YouTube.
`
} else {
  aboutWebsite = `
自然豊かな南紀熊野でのんびり暮らしつつ、折を見て全国各地を旅しています。
定期的にYouTubeへ旅Vlogを投稿しています。
`
}

onMounted(() => {
  setInterval(() => {
    if (state.imageIndex + 1 >= photos.length) {
      state.imageIndex = 0
    } else {
      state.imageIndex += 1
    }
  }, 3000);
})

useHead({
  title: `${t("caption.top")} | ${t("siteName")}`
})
</script>

<template>
  <Navbar></Navbar>
  <div class="container mt-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <p class="about-website">{{ aboutWebsite }}</p>
        <div class="sns-icons-div">
          <a :href="youtubeChannelUrl"><i class="fa-brands fa-youtube"></i></a>
          <a :href="twitterUrl"><i class="fa-brands fa-twitter"></i></a>
          <a :href="instagramUrl"><i class="fa-brands fa-instagram"></i></a>
        </div>
      </div>
    </div>
  </div>
  <section class="post-list-div mt-4">
    <div class="container">
      <h2 class="section-title">
        <i class="fa-regular fa-book"></i>
        <span>{{ t("caption.journalList") }}</span>
      </h2>
    </div>
    <JournalList :journals="journals"></JournalList>
    <div class="post-more">
      <a :href="routePaths.journals()"
         class="btn btn-outline-secondary">{{ t("caption.readMoreJournals") }}</a>
    </div>
  </section>
  <section class="post-list-div mt-4">
    <div class="container">
      <h2 class="section-title">
        <i class="fa-solid fa-newspaper"></i>
        <span>{{ t("caption.postSeriesList") }}</span>
      </h2>
    </div>
    <PostSeriesList :postSeriesList="postSeriesList"></PostSeriesList>
    <div class="post-more">
      <a :href="routePaths.postSeriesList()"
         class="btn btn-outline-secondary">{{ t("caption.readMorePostSeries") }}</a>
    </div>
  </section>
  <section class="post-list-div mt-4">
    <div class="container">
      <h2 class="section-title">
        <i class="fa-solid fa-newspaper"></i>
        <span>{{ t("caption.postList") }}</span>
      </h2>
    </div>
    <PostList :posts="posts"></PostList>
    <div class="post-more">
      <a :href="routePaths.posts()"
         class="btn btn-outline-secondary">{{ t("caption.readMorePosts") }}</a>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use "@/assets/css/frontend_vars.scss" as *;

.about-website {
  margin: 1rem 0.8rem;
}

.section-title {
  margin: 1rem 0.8rem 0.4rem 0.8rem;
  font-size: 1.5rem;
  i {
    color: dimgray;
  }
  span {
    margin-left: 0.4rem;
  }
}

.sns-icons-div {
  display: flex;
  justify-content: center;
  margin: 0.5rem;

  a {
    margin: 0 0.4rem;
    font-size: 2rem;
    color: dimgray;
  }
}

.photo-list-div {
  .photo-image-slider {
    .photo-title {
      display: inline-block;
      width: auto;
      padding: 4px 8px;
      color: white;
      background-color: rgba(black, 0.3);
    }
  }

  .bottom-buttons {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }
}

.post-list-div {
  margin: 30px 0;

  .post-more {
    display: flex;
    justify-content: center;
  }
}

.carousel-center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 5rem;
}
</style>
