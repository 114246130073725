<script setup lang="ts">
import {myNuxtErrorFromAny} from "~/mylib/common/error/MyNuxtError";
import {useMyRoutePaths} from "~/mylib/MyRoutePaths";

import charaImage404Path from '~/assets/images/characters/chara-jirori.png'
import charaImage500Path from '~/assets/images/characters/chara-down.png'

const { t } = useI18n()
const routePaths = useMyRoutePaths()

// API側でセッション無効と判断した時、true
let isInvalidAdminSession = false

const errorRef = useError()
const error = myNuxtErrorFromAny(errorRef.value)
if (error.url?.startsWith("/admin")) {
  if (error.statusCode == 401) {
    isInvalidAdminSession = true
  }
}

let locale = "ja"
if (error.url?.startsWith("/en")) {
  locale = "en"
}

console.log(`Render error.vue page. ${error.message} ${error.url} statusCode:${error.statusCode}`)
console.dir(error)

const isDev = process.dev

const charaImageSource = computed(() => {
  switch (error.statusCode) {
    case 404:
      return charaImage404Path
    default:
      return charaImage500Path
  }
})
</script>

<template>
  <Navbar></Navbar>
  <div class="container my-4">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6">
        <main class="main">
          <img class="img-fluid chara-img" :src="charaImageSource" alt="character">
          <div v-if="isInvalidAdminSession">
            <p><a :href="routePaths.login()">セッションが切れました。再ログインしてください。</a></p>
          </div>
          <div v-else-if="error.statusCode == 404">
            <p>{{ t("message.pageNotFound") }}</p>
          </div>
          <div v-else>
            <p>{{ t("message.pageErrorOccurred") }}</p>
            <pre v-if="isDev">{{error}}</pre>
          </div>
          <div>
            <p>
              <a :href="routePaths.home()" class="btn btn-outline-secondary">{{ t('caption.backToTop') }}</a>
            </p>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main {
  text-align: center;
}

.chara-img {
  max-height: 200px;
}
</style>
